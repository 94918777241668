import React from "react";

import HomeButton from "./HomeButton";

import {
  faUserMd,
  faClinicMedical,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

const HomeHeader = ({ heading, calloutPrimary, calloutSecondary }) => (
  <div className="container pt-8 md:pt-8 md:pb-16 lg:pt-18 lg:pb-24 lg:mt-4">
    <div className="columns items-center">
      <div className="w-full sm:w-8/12 md:w-6/12 lg:w-6/12 md:pr-8 lg:pr-12 space-y-3 md:space-y-4 lg:space-y-6">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-heading text-accent-reverse">
          {heading}
        </h1>
        <p className="text-accent-reverse text-lg lg:text-xl">
          {calloutPrimary}
        </p>
        <p className="text-royal-p1 lg:text-lg">{calloutSecondary}</p>
      </div>

      <div className="w-full sm:w-4/12 space-y-4 md:space-y-5 lg:space-y-8 mb-5 md:mb-0">
        <HomeButton to="/providers" icon={faUserMd} className="accent-foam">
          Providers
        </HomeButton>
        <HomeButton
          to="/pharmacies"
          icon={faClinicMedical}
          className="accent-goldenrod"
        >
          Pharmacies
        </HomeButton>
        <HomeButton to="/patients" icon={faUsers} className="accent-violet">
          Patients
        </HomeButton>
      </div>
    </div>
  </div>
);

export default HomeHeader;

import React from "react";

import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

const HomeButton = ({ className, children, icon, to }) => (
  <Link
    to={to}
    className={`
        group
        flex items-center
        justify-between
        cursor-pointer
        transform
        hover:scale-101
        active:scale-100

        w-full font-heading text-white text-xl
        md:text-2xl
        space-x-3 md:space-x-4
        p-3 md:p-5 lg:py-8 lg:px-7
        rounded-lg bg-accent ${className}`}
  >
    <div className="hidden md:flex lg:text-3xl">
      <FontAwesomeIcon className="text-accent-m2 fa-fw" size="" icon={icon} />
    </div>

    <div className="flex-grow">{children}</div>
    <div className="">
      <FontAwesomeIcon
        className="text-accent-m2 fa-fw"
        size=""
        icon={faAngleRight}
      />
    </div>
  </Link>
);

export default HomeButton;

import React from "react";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const LogoGrid = ({ images, className }) => (
  <ul className={`${className} justify-center flex items-center flex-wrap`}>
    {images.map((image) => (
      <Logo key={image.id} src={image} title={image.name} />
    ))}
  </ul>
);

export const Logo = ({ src, title }) => (
  <li className="w-32 lg:w-1/4 mx-6 my-1" style={{ maxWidth: "112px" }}>
    <PreviewCompatibleImage
      imageInfo={{
        image: src,
        alt: title,
        imageStyle: {
          objectFit: "contain",
        },
      }}
    />
  </li>
);

export default LogoGrid;

// NOTE: Use fragment in page queries to pull data needed for "images" property of <LogoGrid />
export const logoImageFragmentQuery = graphql`
  fragment logoGridImage on File {
    id
    name
    childImageSharp {
      fluid(
        maxWidth: 112
        quality: 90
        traceSVG: { background: "transparent", color: "#69788C" }
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

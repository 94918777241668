import React from "react";

import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";

import LogoGrid from "../components/Logos";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import HomeHeader from "../components/HomeHeader";

import logoSolidWhite from "../img/signature-multi-white.svg";

export const HomePageTemplate = ({
  content,
  contentComponent,
  pressLogos,
  awardLogos,
  ctaPhoto,
  ctaHeading,
  ctaParagraph,
  ctaButtonDestination,
  ctaButtonText,
}) => {
  const PostContent = contentComponent || Content;


  console.log(awardLogos)
  return (
    <>
      <section className="bg-navy-p3 py-8 lg:py-12 xl:py-16">
        <div className="container">
          <h3 className="font-heading text-3xl lg:text-4xl text-center mb-2 mb-12">
            Awards and Recognition
          </h3>
          <div className="columns">
            <LogoGrid
              images={awardLogos}
              className="w-10/12 sm:w-8/12 md:w-7/12 mb-12"
            />
          </div>

          <h3 className="font-heading text-3xl lg:text-4xl text-center mb-2 lg:mb-4">
            Featured in
          </h3>
          <div className="columns">
            <LogoGrid
              images={pressLogos}
              className="w-10/12 sm:w-8/12 md:w-7/12"
            />
          </div>
        </div>
      </section>

      <section className="container -mt-12 md:mt-8 lg:mt-16 xl:mt-24">
        <div className="columns items-center">
          <div className="w-full md:w-8/12 lg:w-6/12">
            <PreviewCompatibleImage
              imageInfo={{
                className: "rounded-xl",
                image: ctaPhoto,
                alt: "Some of the ScriptDrop team",
              }}
            />
          </div>
          <div className="w-full mt-8 sm:mt-12 md:mt-0 text-center md:text-left md:w-4/12 xl:w-6/12 md:pl-8">
            <h3 className="font-heading text-3xl lg:text-4xl mb-2 lg:mb-4">
              {ctaHeading}
            </h3>
            <p className="text-accent sm:w-2/3 sm:mx-auto md:w-auto md:mx-0 text-lg lg:text-xl">
              {ctaParagraph}
            </p>
            <Link
              to={ctaButtonDestination}
              className="btn mt-3 lg:mt-6 lg:px-8 xl:px-16 btn-royal btn-avenir-semibold"
            >
              {ctaButtonText}
            </Link>
          </div>
        </div>
      </section>

      <section class="container">
        <div class="columns">
          <div class="w-full lg:w-10/12">
            <PostContent
              className="mt-8 prose lg:prose-lg max-w-none"
              content={content}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      headerTitle={post.frontmatter.title}
      navbarLogo={logoSolidWhite}
      headerClassName="sm:bg-homepage-sm lg:bg-homepage bg-no-repeat bg-cover bg-navy md:bg-navy-p3 bg-left-bottom"
      headerContent={<HomeHeader {...post.frontmatter} />}
      className="accent-navy"
    >
      <Helmet titleTemplate="%s">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>

      <HomePageTemplate
        pressLogos={post.frontmatter.pressLogos}
        awardLogos={post.frontmatter.awardLogos}
        ctaPhoto={post.frontmatter.ctaPhoto}
        ctaHeading={post.frontmatter.ctaHeading}
        ctaParagraph={post.frontmatter.ctaParagraph}
        ctaButtonText={post.frontmatter.ctaButtonText}
        ctaButtonDestination={post.frontmatter.ctaButtonDestination}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

export default HomePage;

export const aboutPageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        calloutPrimary
        calloutSecondary
        pressLogos {
          ...logoGridImage
        }
        awardLogos {
          ...logoGridImage
        }
        ctaPhoto {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 90
              traceSVG: { background: "transparent", color: "#D4E6FE" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        ctaHeading
        ctaParagraph
        ctaButtonText
        ctaButtonDestination
      }
    }
  }
`;
